<template>
  <div class="setting-repartition-zone-th">
    <div class="box-setting-header ">
      <div class="d-flex">
        <div class="title mr-4">Répartition zone</div>

        <div v-if="getLoadingDepotTh" class="loading">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>

      <div>
        <b-button
          v-if="checkPermission('RZTHA')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addDepotModal
          ><font-awesome-icon icon="plus" /> Ajouter un dépôt de stockage
        </b-button>
        <b-modal
          ref="addDepotModal"
          id="addDepotModal"
          title="Ajouter une année"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un dépôt de stockage</div>
            <div class="iconClose" @click.prevent="hideModal('addDepotModal')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitDepot"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label=""
                  label-for="depot-name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="depot-name"
                    v-model="depotToAdd"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style mt-3"
                    :disabled="loading"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header mt-2"></div>

    <!-- <div v-if="getLoadingDepotTh" class=" chargement-message-calender ml-2">
      Chargement en cours ...
    </div> -->
    <div class="error-message">
      <div v-if="getErrorDepotTh" class="error">
        <ul v-if="Array.isArray(getErrorDepotTh)">
          <li v-for="(e, index) in getErrorDepotTh" :key="index">
            {{ e }}
          </li>
        </ul>
        <div v-else>{{ getErrorDepotTh }}</div>
      </div>
    </div>

    <div class="calender-body">
      <div class="calender-card">
        <div
          v-for="item in getDepotsTh"
          :key="'depot' + item.id"
          class="card-mounts"
        >
          <div>
            <div class="card-mounths-header ">
              <input
                class="input-focus-depot input-focus-depot-width"
                placeholder="Nom de dépôt"
                @change="updateDataDepot($event, item)"
                title="Modifier
                  le nom de dépôt"
                :value="item.depot"
              />
              <font-awesome-icon
                v-if="checkPermission('RZTHS')"
                icon="times"
                class="icon-flesh"
                title="Supprimer la dépôt"
                @click="tryDeleteDepot(item)"
              />
            </div>
            <div class="card-mountes-body">
              <div
                class="card-mounths-item"
                v-for="itemD in item.departement"
                :key="'departement' + itemD.id"
              >
                <div class="departement-bloch-data-style">
                  <input
                    class="input-focus-depot"
                    placeholder="Nom de dépôt"
                    @change="updateDataDepartement($event, item, itemD)"
                    title="Modifier
                  le nom de département"
                    :value="itemD.departement"
                  />

                  <b-form-select
                    class="b-form-select-new-style b-form-select-new-style-custom"
                    v-model="itemD.zone"
                    :options="[
                      { vaue: 'H1' },
                      { vaue: 'H2' },
                      ,
                      { vaue: 'H3' }
                    ]"
                    value-field="vaue"
                    text-field="vaue"
                    @change="updateZone(itemD.zone, item, itemD)"
                  ></b-form-select>
                </div>
                <div>
                  <font-awesome-icon
                    v-if="checkPermission('RZTHS')"
                    icon="times"
                    class="icon-flesh"
                    title="Supprimer la département"
                    @click="tryDeleteDepartement(item, itemD)"
                  />
                </div>
              </div>
              <div class="button-add-depot">
                <b-button
                  variant="success"
                  class="depot-add-btn  ml-2"
                  v-b-modal.addDepartementModal
                  @click="handleShowAddDepartementModal(item)"
                  ><font-awesome-icon icon="plus" /> Département</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="addDepartementModal"
      id="addDepartementModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Ajouter un département</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('addDepartementModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitDepartement"
            class="form-modal-custom-style"
          >
            <div class="depot-card-modal-repartition">
              <div class="box-label-champ mr-2">
                <div class="label-box-style w-103-px p-4-5">
                  <span class="title-tabel">Zone climatique</span>
                </div>
                <b-form-select
                  class="b-form-select-new-style"
                  v-model="departementToAdd.zone_climatique"
                  :options="[{ vaue: 'H1' }, { vaue: 'H2' }, , { vaue: 'H3' }]"
                  value-field="vaue"
                  text-field="vaue"
                ></b-form-select>
              </div>
              <b-form-group
                label=""
                label-for="departement-name"
                class="input-modal-champ mb-0"
              >
                <b-form-input
                  autocomplete="off"
                  id="departement-name"
                  v-model="departementToAdd.name"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="getLoadingDepotTh"
              >
                <span>
                  Valider
                  <div v-if="getLoadingDepotTh" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="deleteDepartementModal"
      id="deleteDepartementModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <template>
        <form
          @submit.prevent="handleDeleteDepartement"
          class="form-modal-custom-style"
        >
          <div class="hader mb-1 mt-1">
            <div></div>
            <div
              class="iconClose"
              @click.prevent="hideModal('deleteDepartementModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <div class="ModelCol">
            <p class="mb-3 mr-1">
              Déplacer le
              <strong>
                département
                {{ selectedDepartement ? selectedDepartement.departement : '' }}
              </strong>
              de
              <strong>{{ selectedDepot ? selectedDepot.depot : '' }} </strong>
              vers :
            </p>
            <div>
              <b-form-group v-if="selectedDepartement" class="mb-0">
                <b-form-select
                  class="b-form-select-raduis  custom-selectm "
                  v-model="selectedDepartement.depot_id"
                  :options="getDepotsTh"
                  value-field="id"
                  text-field="depot"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div class="actionModel">
            <div class="messageError">
              <div v-if="error" class="error">
                <ul v-if="Array.isArray(error)">
                  <li v-for="(e, index) in error" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ error }}</div>
              </div>
            </div>
            <b-button
              type="submit"
              class="button-valide-style mt-3"
              :disabled="getLoadingDepotTh"
            >
              <span>
                Valider
                <div v-if="getLoadingDepotTh" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div>
        </form>
      </template>
    </b-modal>
    <b-modal
      ref="deleteDepotModal"
      id="deleteDepotModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <template>
        <form
          @submit.prevent="handleDeleteDepot"
          class="form-modal-custom-style"
        >
          <div class="hader mb-1 mt-1">
            <p class="mb-3">
              Supprimer un dépôt
            </p>
            <div
              class="iconClose"
              @click.prevent="hideModal('deleteDepotModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>

          <div>
            Êtes-vous certain de vouloir supprimer la dépôt
            <strong>
              {{
                selectedDepotToDelete ? selectedDepotToDelete.depot : '_'
              }} </strong
            >?
          </div>

          <div class="actionModel">
            <div class="messageError">
              <div v-if="error" class="error">
                <ul v-if="Array.isArray(error)">
                  <li v-for="(e, index) in error" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ error }}</div>
              </div>
            </div>
            <b-button
              type="submit"
              class="button-valide-style mt-3"
              :disabled="getLoadingDepotTh"
            >
              <span>
                Valider
                <div v-if="getLoadingDepotTh" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Répartition-des-zones',
  data() {
    return {
      depotToAdd: null,
      error: null,
      loading: false,
      departementToAdd: {
        name: null,
        zone_climatique: null
      },
      depotToUpdate: null,
      selectedDepot: null,
      selectedDepartement: null,
      selectedDepotToDelete: null
    };
  },
  methods: {
    ...mapActions([
      'AddDepotTh',
      'fetchDepotsTh',
      'addDepartementToDepot',
      'removeDepartmentFromDepot',
      'updateNameDepartementTh',
      'updateNameDepotRTh',
      'deleteDepotTh'
    ]),
    resetModal() {
      this.depotToAdd = null;
      this.error = null;
      this.loading = false;
      this.departementToAdd = {
        name: null,
        zone_climatique: null
      };
      this.depotToUpdate = null;
      this.selectedDepotToDelete = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleSubmitDepot() {
      this.loading = true;
      const response = await this.AddDepotTh(this.depotToAdd);
      if (response.success) {
        this.error = null;
        this.loading = false;
        this.hideModal('addDepotModal');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    handleShowAddDepartementModal(item) {
      this.depotToUpdate = item;
    },
    async handleSubmitDepartement() {
      const response = await this.addDepartementToDepot({
        depot: this.depotToUpdate,
        departement: this.departementToAdd
      });
      if (response.succes == true) {
        this.hideModal('addDepartementModal');
      } else {
        this.error = response.error;
      }
    },
    tryDeleteDepartement(depot, departement) {
      this.selectedDepot = depot;
      this.selectedDepartement = { ...departement };
      this.$refs['deleteDepartementModal'].show();
    },
    async handleDeleteDepartement() {
      if (this.selectedDepot.id == this.selectedDepartement.depot_id) {
        this.error = 'Choisir un nouveau dépot';
        return;
      }
      const response = await this.removeDepartmentFromDepot({
        depot: this.selectedDepot,
        departement: this.selectedDepartement
      });
      if (response) {
        this.$refs['deleteDepartementModal'].hide();
      }
    },

    tryDeleteDepot(depot) {
      this.selectedDepotToDelete = { ...depot };
      this.$refs['deleteDepotModal'].show();
    },
    async handleDeleteDepot() {
      const response = await this.deleteDepotTh(this.selectedDepotToDelete);
      if (response.succes) {
        this.$refs['deleteDepotModal'].hide();
      } else {
        this.error = response.error;
      }
    },
    updateDataDepartement(e, depot, departement) {
      this.updateNameDepartementTh({
        id: departement.id,
        name: e.target.value,
        zone: departement.zone,
        depot: depot,
        depot_id: depot.id,
        departement: departement
      });
    },
    updateZone(e, depot, departement) {
      this.updateNameDepartementTh({
        id: departement.id,
        zone: e,
        name: departement.departement,
        depot: depot,
        depot_id: depot.id
      });
    },
    updateDataDepot(e, depot) {
      this.updateNameDepotRTh({
        name: e.target.value,
        depot: depot
      });
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingDepotTh',
      'getDepotsTh',
      'getErrorDepotTh',
      'checkPermission'
    ]),
    computedDepotName() {
      return function(name) {
        const words = name.split('-');
        return { year: words[0], name: words[1] };
      };
    }
  },
  components: {
    Card: () => import('../component/card.vue')
  },
  filters: {
    FormateMonthName: value => {
      return value;
    }
  },
  mounted() {
    this.fetchDepotsTh();
  }
};
</script>

<style scoped lang="scss">
.setting-repartition-zone-th {
  width: 100%;

  .calender-body {
    margin-top: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 155px);
    .calender-card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 7px;
      justify-content: center;
    }
  }
  .card-mounts {
    border: 1px solid rgba(108, 117, 125, 0.30196078431372547);
    margin-right: 8px;
    background-color: #332fc838;
    border-radius: 4px;
    display: table-cell;
    vertical-align: top;
    padding: 2px;
    margin: 5px;
    width: 120px;
    .card-mounths-header {
      position: relative;
      width: 100%;
      padding: 0px;
      font-weight: 600;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
    }
    .card-mountes-body {
      .card-mounths-item {
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
        padding: 4px;
        background-color: rgb(245 244 250);
        width: 100%;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
      }

      .button-add-depot {
        margin: auto;
        margin-top: 5px;
        text-align: center;
      }
    }
    .input-focus-depot {
      width: 45%;
      background-color: transparent;
      border: none;
      color: #000;
      overflow: visible;
      background-color: transparent;
      border: 0;
      font-size: 13px;
      text-overflow: ellipsis;
      outline: unset;
      height: 19px;
      &:focus {
        background-color: #e4e4fb;
      }
    }
    .input-focus-depot-width {
      width: 85%;
    }
    .icon-flesh {
      cursor: pointer;
      font-size: 10px;
      margin-right: 2px;
      color: #6f6ecb;
      &:hover {
        font-size: 13px;
      }
    }
  }
  .departement-bloch-data-style {
    display: flex;
    .b-form-select-new-style-custom {
      border-radius: 5px;
      width: 46px;
      background-color: #fcfcfc;
      padding-left: 2px;
      padding-top: 0px;
      padding-bottom: 0px;
      height: 20px;
    }
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
<style lang="scss">
.depot-card-modal-repartition {
  display: inline-flex;
  .label-modal {
    font-size: 11px;
    padding: 5px;
    font-weight: 800;
    color: #2a2a2a;
  }
}
</style>
